import { ClickIds } from "./models/click-ids";
import { User } from "./models/user";
import { UtmValues } from "./models/utm-values";

class StorageService {
    public getPreviousPage(): string {
        return sessionStorage.getItem('previousPage') as string;
    }

    public setPreviousPage(v: string) {
        sessionStorage.setItem('previousPage', v);
    }

    public getClickedLogin(): boolean {
        return Boolean(localStorage.getItem('clickedLogin'));
    }
    public setClickedLogin(v: boolean) {
        localStorage.setItem('clickedLogin', v.toString());
    }

    public getReferralRockCode(): string {
        return sessionStorage.getItem('REFERRALCODE') as string;
    }

    public setReferralRockCode(v: string) {
        sessionStorage.setItem('REFERRALCODE', v)
    }

    public getBdcAccountingSoftwareForDivvy(): string {
        return sessionStorage.getItem('bdcAccountingSoftwareForDivvy') as string;
    }

    public setBdcAccountingSoftwareForDivvy(v: string) {
        sessionStorage.setItem('bdcAccountingSoftwareForDivvy', v)
    }
    
    public getOfferId(): string | null {
        return localStorage.getItem('bdcOfferId');
    }
    public setOfferId(v: string) {
        localStorage.setItem('bdcOfferId', v);
    }

    // public getExpiringUserData() {
    //     return this.getLocalWithExpiry('bdc_em_capture');
    // }

    // public setExpiringUserData(value: Object) {
    //     this.setLocalWithExpiry('bdc_em_capture', value, 90 * 24 * 60 * 60 * 1000); // 90 days
    // }

    public getFirstTouchValues(): UtmValues {
        return JSON.parse(localStorage.getItem('utm_values') as string);
    }

    public hasFirstTouchValues(): boolean {
        return 'utm_values' in localStorage;
    }

    public setFirstTouchValues(v: UtmValues) {
        localStorage.setItem('utm_values', JSON.stringify(v));
    }

    public getLastTouchValues(): UtmValues | null {
        try {
            return this.getLocalWithExpiry('utm_values_lt');
        } catch(e) {
            // workaround for values without expiration date
            // after Nov 19, 2023 all LT values are considered expiried,
            // this check can be removed after the date and should return null
            return new Date() > new Date(2023, 10, 19) ? null : JSON.parse(localStorage.getItem('utm_values_lt')!);
        }
    }

    public hasLastTouchValues(): boolean {
        return Boolean(this.getLastTouchValues());
    }

    public setLastTouchValues(v: UtmValues) {
        const days30 = 30 * 24 * 60 * 60 * 1000;
        this.setLocalWithExpiry('utm_values_lt', v, days30);
    }

    public setLastTouchPactId(pactId: string) {
        const lt = this.getLastTouchValues() || {} as any;        
        lt.pactId = pactId;
        this.setLastTouchValues(lt);
    }

    public getClickIds(): ClickIds {
        return JSON.parse(localStorage.getItem('clickIds') as string);
    }

    public setClickIds(v: ClickIds) {
        localStorage.setItem('clickIds', JSON.stringify(v));
    }

    public getIsFirstTouchSession(): boolean {
        return 'isFirstTouch' in sessionStorage;
    }

    public setIsFirstTouchSession(v: boolean) {
        const s = sessionStorage;
        v ? s.setItem('isFirstTouch', '') : s.removeItem('isFirstTouch');
    }

    public setReferralRockReferralUrl(v: string) {
        window.sessionStorage.setItem('referralRockReferralUrl', v);
    }

    public getUserData(): Partial<User> | null {
        const user = window.localStorage.getItem('bdcUserData');
        return user && JSON.parse(user);
    }

    public setUserData(v: Partial<User>) {
        window.localStorage.setItem('bdcUserData', JSON.stringify(v));
    }

    public getConsolePricePlan(): string | null {
        return window.localStorage.getItem('bdc-consolepriceplan')
    }

    /**
     * Set local storage key/value with expiration
     * @param ttl time to live in ms
     */
    private setLocalWithExpiry(key: string, value: any, ttl: number) {
        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiresOn: Date.now() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
    }

    private getLocalWithExpiry(key: string) {
        const item = JSON.parse(localStorage.getItem(key) as string);
        // if the item doesn't exist, return null
        if (!item) return null;
        if (!item.expiresOn) throw "Stored object does not support expiration";
        // when expired delete the entry from storage
        // and return null
        if (Date.now() > item.expiresOn) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    }
}

export const storageService = new StorageService();